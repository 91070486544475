<template>
  <div>
    <!-- begin #header -->
    <div id="header" class="header navbar-default">
      <!-- begin navbar-header -->
      <div class="navbar-header">
        <router-link to="/dashboard" class="navbar-brand">
          <img
            src="../../assets/img/logo-teks-sidara.png"
            alt="Logo"
            style="height: 32px"
          />
        </router-link>
        <button
          type="button"
          class="navbar-toggle pt-0 pb-0 mr-0 collapsed"
          v-on:click="toggleMobileTopMenu"
          v-if="pageOptions.pageWithTopMenu && !pageOptions.pageWithoutSidebar"
        >
          <span class="fa-stack fa-lg text-inverse">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button
          type="button"
          class="navbar-toggle"
          v-on:click="toggleMobileTopMenu"
          v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button
          type="button"
          class="navbar-toggle"
          v-on:click="toggleMobileSidebar"
          v-if="!pageOptions.pageWithoutSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- end navbar-header -->

      <!-- begin header-nav -->
      <ul class="navbar-nav d-flex flex-grow-1">
        <li class="navbar-form flex-grow-1">
          <span style="font-weight: bold">
            Menuju 27 November : <br />
            {{ countdown.days }} Hari, {{ countdown.hours }} Jam,
            {{ countdown.minutes }} Menit, {{ countdown.seconds }} Detik
          </span>
        </li>
        <b-nav-item-dropdown
          menu-class="dropdown-menu-right"
          class="dropdown navbar-user"
          no-caret
        >
          <template slot="button-content">
            <img :src="imageProfile" alt="" />
            <span class="d-none d-md-inline">{{ userFullName }}</span>
            <b class="caret"></b>
          </template>
          <b-dropdown-item href="javascript:;" v-on:click="openProfil"
            >Profile</b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="javascript:;" v-on:click="logout"
            >Log Out</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </ul>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  name: "Header",
  mixins: [sipData],
  data() {
    return {
      pageOptions: PageOptions,
      targetDate: new Date("2024-11-27T00:00:00"),
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      imageProfile: "../assets/img/user.png",
    };
  },
  mounted() {
    this.startCountdown();
    if (this.userPhoto) {
      this.imageProfile = this.userPhoto;
    }
  },
  methods: {
    logout() {
      Swal.fire({
        title: "Apakah Anda Akan Logout ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Logout!",
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("sipData");
          if (this.$route.path !== "/login") {
            this.$router.push("/login");
          }
        }
      });
    },
    openProfil() {
      this.$router.push("/profil");
    },
    toggleMobileSidebar() {
      this.pageOptions.pageMobileSidebarToggled =
        !this.pageOptions.pageMobileSidebarToggled;
    },
    toggleMobileRightSidebar() {
      this.pageOptions.pageMobileRightSidebarToggled =
        !this.pageOptions.pageMobileRightSidebarToggled;
    },
    toggleMobileTopMenu() {
      this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
    },
    toggleMobileMegaMenu() {
      this.pageOptions.pageMobileMegaMenu =
        !this.pageOptions.pageMobileMegaMenu;
    },
    toggleRightSidebar() {
      this.pageOptions.pageRightSidebarToggled =
        !this.pageOptions.pageRightSidebarToggled;
    },
    toggleRightSidebarCollapsed() {
      this.pageOptions.pageRightSidebarCollapsed =
        !this.pageOptions.pageRightSidebarCollapsed;
    },
    handleSidebarMinify: function () {
      this.pageOptions.pageSidebarMinified =
        !this.pageOptions.pageSidebarMinified;
    },
    checkForm: function (e) {
      e.preventDefault();
      this.$router.push({ path: "/extra/search" });
    },
    startCountdown() {
      this.timer = setInterval(() => {
        const now = new Date().getTime();
        const timeDifference = this.targetDate - now;

        if (timeDifference > 0) {
          this.countdown.days = Math.floor(
            timeDifference / (1000 * 60 * 60 * 24)
          );
          this.countdown.hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          this.countdown.minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          this.countdown.seconds = Math.floor(
            (timeDifference % (1000 * 60)) / 1000
          );
        } else {
          clearInterval(this.timer);
          console.log("Hitung mundur selesai!");
        }
      }, 1000);
    },
  },
};
</script>
